// extracted by mini-css-extract-plugin
export var copyright = "footer-module--copyright--d4641";
export var enlaces = "footer-module--enlaces--023b4";
export var final = "footer-module--final--fcf38";
export var foot = "footer-module--foot--ce581";
export var footer = "footer-module--footer--2b30a";
export var imgfoot = "footer-module--imgfoot--d7e2a";
export var informacion = "footer-module--informacion--b46ce";
export var last = "footer-module--last--3fce4";
export var oficial = "footer-module--oficial--4c7b2";
export var sitio = "footer-module--sitio--660a1";
export var uso = "footer-module--uso--10863";