import React from "react";
import * as styles from "./footer.module.css";
import { Link } from "gatsby";

const Footer = () => {
    return (
        <div id={styles.footer}>
            <div className={styles.enlaces}>
                <ul>
                    <li><Link to="/escritos">Escritos Bahá'ís</Link></li>
                    <li><Link to="/sitios-bahais">Sitios Bahá'ís</Link></li>
                    <li><Link to="/contacto">Contacto</Link></li>
                </ul>
            </div>
            <div className={styles.sitio}>
                <div className={styles.oficial}>Sitio oficial de la Comunidad Bahá'í de CUBA</div>
            </div>
            <div className={styles.final}>
                <div className={styles.uso}><Link to="/condiciones">Condiciones de uso</Link>
                    <div className={styles.copyright}>© 2023</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;