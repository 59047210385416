import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./mobilemenu.module.css";
import MobileMenuItem from "./MobileMenuItem";

const MobileMenu = ({ location }) => {
    const menus = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            menu {
              items {
                id pageUrl title
              } id name
            }
          }
        }
      }
    `)?.site?.siteMetadata?.menu;

    const menuRendered = menus.map(menu => <MobileMenuItem key={"mobile-menu-item-" + menu.id} menu={menu} location={location} />);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return (
        <div className={styles.mobileMenu}>
            <div className={styles.mobileMenuBotMenu} role="button" tabIndex={0} onClick={() => setIsMenuOpen(!isMenuOpen)} onKeyDown={() => setIsMenuOpen(!isMenuOpen)}>Menu</div>
            <div className={`${styles.MobileMenuSubmenu} ${isMenuOpen ? "" : styles.hidden}`}>
                <div className={styles.mobileMenuIzquierda}>
                    <ul>
                        <li className="b-v-esp"><a href=".">Inicio</a></li>
                        <li className="b-creemos">En qué creemos</li>
                        <li className="b-hacemos">Qué hacemos</li>
                        <li className="b-somos">Quiénes somos</li>
                        <li className="b-escritos">Escritos bahá'ís</li>
                        <li className="b-contac">Contacto</li>
                    </ul>
                </div>
                {menuRendered}
            </div>
        </div>
    );
}

export default MobileMenu;