import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./header.css";

const Header = ({ siteTitle }) => {
    const [openIndex, setOpenIndex] = useState(0);

    return (
        <header>
            <div id="top"></div>
            <div id="header">
                <div id="header-title">
                    <Link id="header-title-url" to="/">{siteTitle}</Link>
                </div>
                <div className="menusup">
                    <div className="extremo"></div>
                    <div className="box">
                        <div className="enlace"><Link to="/">Inicio</Link></div>
                    </div>
                    <div id="queCremosMenuItem" className="box" role="menu" tabIndex={0} onMouseEnter={() => setOpenIndex(1)} onMouseLeave={() => setOpenIndex(0)}>
                        <div className="enlace"><Link to="/que-creemos">En qué creemos</Link></div>
                        <div className={`subbox ${openIndex !== 1 ? "hidden" : ""}`}>
                            <div className="enlace topmenu"><Link to="/unidad-humanidad">La unidad de la humanidad</Link></div>
                            <div className="enlace topmenu"><Link to="/bahaullah">Bahá'u'lláh, El Educador Divino</Link></div>
                            <div className="enlace topmenu last"><Link to="/vida-espiritual">La vida espiritual</Link></div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="enlace"><Link to="/que-hacemos">Qué hacemos</Link></div>
                    </div>
                    <div className="box">
                        <div className="enlace"><Link to="/quienes-somos">Quiénes somos</Link></div>
                    </div>
                    <div id="escritosMenuItem" className="box" role="menu" tabIndex={0} onMouseEnter={() => setOpenIndex(2)} onMouseLeave={() => setOpenIndex(0)}>
                        <div className="enlace"><Link to="/escritos">Escritos bahá'ís</Link></div>
                        <div className={`subbox ${openIndex !== 2 ? "hidden" : ""}`}>
                            <div className="enlace topmenu"><Link to="/escritos-bahaullah">Escritos de Bahá’u’lláh</Link></div>
                            <div className="enlace topmenu"><Link to="/escritos-bab">Escritos del Báb</Link></div>
                            <div className="enlace topmenu"><Link to="/escritos-abdulbaha">Escritos de ‘Abdu’l-Bahá</Link></div>
                            <div className="enlace topmenu"><Link to="/obras-shoghi-effendi">Obras de Shoghi Effendi</Link></div>
                            <div className="enlace topmenu last"><Link to="/documentos-cuj">La Casa Universal de Justicia</Link></div>
                        </div>
                    </div>
                    <div id="contactoMenuItem" className="box last" role="menu" tabIndex={0} onMouseEnter={() => setOpenIndex(3)} onMouseLeave={() => setOpenIndex(0)}>
                        <div className="enlace"><Link to="/contacto">Contacto</Link></div>
                        <div className={`subbox ${openIndex !== 3 ? "hidden" : ""}`}>
                            <div className="enlace topmenu last"><Link to="/sitios-bahais">Sitios bahá'ís</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;