import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import MobileMenu from "./MobileMenu/MobileMenu";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "./layout.css";

const Layout = ({ children, location }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery { site { siteMetadata { title } } }
    `);

    return (
        <div className="gradient">
            <MobileMenu location={location} />
            <div className="empaque">
                <div className="container">
                    <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
                    <main>{children}</main>
                </div>
                <Footer />
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;